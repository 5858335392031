let enquire
if (typeof window !== 'undefined') {
  const matchMediaPolyfill = mediaQuery => ({
    media: mediaQuery,
    matches: false,
    addListener() {},
    removeListener() {},
  })
  window.matchMedia = window.matchMedia || matchMediaPolyfill
  enquire = require('enquire.js') // eslint-disable-line global-require
}

export function enquireScreen(cb) {
  if (!enquire) {
    return
  }
  /* eslint-disable no-unused-expressions */
  // and (min-width: 320px)
  enquire.register('only screen and (max-width: 768px)', {
    match: () => {
      cb && cb(true)
    },
    unmatch: () => {
      cb && cb()
    },
  })
  /* eslint-enable no-unused-expressions */
}
